#switch {
  display: none;
}

.switch-label {
  display: inline-block;
  cursor: pointer;
  background: #fff;
  color: #111;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0 2px #666;
}
.switch-label::after {
  content: "on";
}
.switch-label::before {
  content: " ";
  display: inline-block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  background: #003321;
  margin-right: 10px;
  border: 1px solid #111;
}
.switch-label:active {
  box-shadow: none;
  transform: translate3d(0, 2px, 0);
}

#switch:checked + .switch-label::before {
  background: #22ff55;
}
#switch:checked + .switch-label::after {
  content: "off";
}

@keyframes flicker {
  0% {
    opacity: 0.37853;
  }
  5% {
    opacity: 0.49103;
  }
  10% {
    opacity: 0.87254;
  }
  15% {
    opacity: 0.63387;
  }
  20% {
    opacity: 0.29716;
  }
  25% {
    opacity: 0.82344;
  }
  30% {
    opacity: 0.12765;
  }
  35% {
    opacity: 0.10309;
  }
  40% {
    opacity: 0.13099;
  }
  45% {
    opacity: 0.01436;
  }
  50% {
    opacity: 0.52964;
  }
  55% {
    opacity: 0.50872;
  }
  60% {
    opacity: 0.78223;
  }
  65% {
    opacity: 0.92772;
  }
  70% {
    opacity: 0.10675;
  }
  75% {
    opacity: 0.34916;
  }
  80% {
    opacity: 0.17816;
  }
  85% {
    opacity: 0.51809;
  }
  90% {
    opacity: 0.33315;
  }
  95% {
    opacity: 0.09637;
  }
  100% {
    opacity: 0.8632;
  }
}
.crtContainer {
  background: #121010;
  position: relative;
  overflow: hidden;
}
.crtContainer::after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
}
.crtContainer::before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

#switch:checked ~ .crtContainer::after {
  animation: flicker 0.15s infinite;
}

@keyframes turn-on {
  0% {
    transform: scale(1, 0.8) translate3d(0, 0, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 1;
  }
  3.5% {
    transform: scale(1, 0.8) translate3d(0, 100%, 0);
  }
  3.6% {
    transform: scale(1, 0.8) translate3d(0, -100%, 0);
    opacity: 1;
  }
  9% {
    transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
    -webkit-filter: brightness(30);
    filter: brightness(30);
    opacity: 0;
  }
  11% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(0) brightness(0);
    filter: contrast(0) brightness(0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1) translate3d(0, 0, 0);
    -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
    filter: contrast(1) brightness(1.2) saturate(1.3);
    opacity: 1;
  }
}
@keyframes turn-off {
  0% {
    transform: scale(1, 1.3) translate3d(0, 0, 0);
    -webkit-filter: brightness(1);
    filter: brightness(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3, 0.001) translate3d(0, 0, 0);
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  100% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: scale(0, 0.0001) translate3d(0, 0, 0);
    -webkit-filter: brightness(50);
    filter: brightness(50);
  }
}
.screen {
  width: 100%;
  height: 100%;
  border: none;
}

#switch ~ .crtContainer > .screen {
  animation: turn-off 0.55s cubic-bezier(0.23, 1, 0.32, 1);
  animation-fill-mode: forwards;
}

#switch:checked ~ .crtContainer > .screen {
  animation: turn-on 4s linear;
  animation-fill-mode: forwards;
}

@keyframes overlay-anim {
  0% {
    visibility: hidden;
  }
  20% {
    visibility: hidden;
  }
  21% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}
.overlay {
  color: #00ff00;
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 35px;
  visibility: hidden;
  pointer-events: none;
}

#switch:checked ~ .crtContainer .overlay {
  animation: overlay-anim 5s linear;
  animation-fill-mode: forwards;
}
